.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header img {
  height: 100px;   
  width: auto;   
  margin-top: 50px; 
  margin-bottom: 20px;
}


.App-header {
  background-color: #f3f4ef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #da9c27;
}

.survey-container {
  text-align: left;
}

.survey-container select {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  font-size: 15px;
  color: #333;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  width: 300px;
}
.survey-container label {
  display: block;
  margin-bottom: 10px;
}
.survey-container select:hover {
  border-color: #da9c27;
}
.survey-container select:focus {
  outline: none;
  border-color: #edb03d;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chart-wrapper .recharts-text {
  font-size: 15px !important;  
}

p {
  font-size: 15px;
}

h1 {
  font-size: larger;
}
h2 {
  font-size: large;
}
h3 {
  font-size: medium;
}

.comparison-button {
  margin: 10px;  /* Adjust the margin value as needed */
}

/* Targeting tooltip text inside the chart-wrapper */
.chart-wrapper .recharts-tooltip-wrapper,
.chart-wrapper .recharts-default-tooltip {
    font-size: 15px !important; /* Ensures override of inline styles or defaults */
}

.survey-container label {
  font-size: 15px;
  font-weight: 600;
}

.survey-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin: 20px auto;
  max-width: 600px;
  color: black;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.slider-container {
  margin: 20px 0;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #eeeeee;
  outline: none;
  transition: background 0.3s ease;
}

input[type="range"]:hover {
  background: #e6e6e6;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #da9c27;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #da9c27;
  cursor: pointer;
}

button {
  background-color: #da9c27;
  color: white;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.slider-value-label {
  background-color: #da9c27; /* Dark gray background */
    color: white;             /* White text for contrast */
    padding: 7px 10px;        /* Padding around the text */
    font-weight: 700 !important;
    width: 10px;              /* Only as wide as its content requires */
    text-align: right;
}


button:hover {
  background-color: #c58d23;
}

.comparison-options {
  overflow: auto;
  padding: 10px 30px;  /* Adds space inside the container */
  background-color: #f4f4f4; /* Light grey background color */
  border-radius: 8px; /* Rounds the corners of the box */
}
.comparison-options p {
  font-weight: 700 !important;
}